(function (window, document, $) {

    $(document).ready(function () {

        scrollSpy();

        $(window).on('resize', function () {
            scrollSpy();
        });

        function getNavbarHeight() {
            var navbar = $('#navbar'),
                nav = navbar.closest('nav'),
                navbarHeight = navbar.prev().height() || navbar.height(),
                navPadding = parseInt(nav.css('paddingTop'));
            return navbarHeight + navPadding;
        }

        function scrollSpy() {
            // Scroll spy should make section active if previous section content is not visible anymore
            var firstSection = $('section .container').first(),
                sectionPadding = parseInt(firstSection.css('paddingTop'));
            $('body').scrollspy({
                target: '#navbar',
                offset: getNavbarHeight() + sectionPadding
            });
        }

        $.fancybox.defaults.animationEffect = "slide";
        $.fancybox.defaults.hideScrollbar = false;
        $('[data-popup]').click(function (event) {
            $.fancybox.open({
                src: '#' + $(this).attr('data-popup'),
                autoFocus: false, // Prevent focusing <input> element
            });
        });

        $(document).keyup(function (e) {
            if (e.key === 'Escape') {
                $.fancybox.close();
            }
        });

        // Close mobile menu when link is clicked
        $('.navbar-collapse').on('click', function () {
            if ($(this).hasClass('in')) {
                $('#navigation .navbar-collapse').collapse('hide');
            }
        })

    });

})(window, document, jQuery);